import React from 'react'
import ContactUs from '../components/molecules/ContactUs'
import JoinUsForm from '../components/molecules/JoinUsForm'
import Testimonials from '../components/organisms/Testimonials'
import Branding from '../components/molecules/Branding/Branding'
import LanguageWrapper from '../components/molecules/LanguageWrapper/LanguageWrapper'
import ExperienceDesc from '../components/molecules/ExperienceDesc/ExperienceDesc'
import LanguageServices from '../components/organisms/LanguageServices/LanguageServices'
import { Helmet } from 'react-helmet'

const FullstackPage = () => {
    return (
        <div>
            <Helmet>
                <link rel="canonical" href="https://thecodingstudio.in/services/fullstack" />
                <link rel="alternate" href="https://thecodingstudio.in/services/fullstack" hreflang="x-default" />
            </Helmet>
            <LanguageWrapper background={'bg-fullstack-development'} />
            <ExperienceDesc title='fullstack' />
            {/* <SatisfactionServices /> */}
            <Branding />
            <LanguageServices title='Fullstack' language='fullstack' filterKey='language' />
            <Testimonials />
            {/* <LanguageTeam role="Fullstack Developer" title="Fullstack" /> */}
            <div className='mx-auto lg:mx-[-100px] mt-[100px]'>
                <JoinUsForm />
            </div>
            <ContactUs />
        </div>
    )
}

export default FullstackPage