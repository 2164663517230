import React from 'react'
import ButtonComponent from '../components/atoms/Button'
import ContactUs from '../components/molecules/ContactUs'
import ProjectStats from '../components/molecules/ProjectStats'
import OurServices from '../components/organisms/OurServices'
import ServiceTable from '../components/organisms/ServiceTable'
import Technologies from '../components/organisms/Technologies'
import Testimonials from '../components/organisms/Testimonials'
import Branding from '../components/molecules/Branding/Branding'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const ServicesPage = () => {
    const navigate = useNavigate();
    return (
        <div className='text-black px-2 md:px-[20px] lg:px-[100px] pb-6'>
            <Helmet>
                <link rel="canonical" href="https://thecodingstudio.in/services" />
                <link rel="alternate" href="https://thecodingstudio.in/services" hreflang="x-default" />
            </Helmet>
            <div className='flex flex-col items-center bg-[center] [background-size:100%] p-[10%_4%_18%_10%] bg-services bg-no-repeat w-[100%] relative z-[1]'>
                <div className='flex justify-center gap-8'>
                    <img data-aos="fade-right" className='w-[10%] mb-[10%]' src='/images/profile-pic.png' alt='' />
                    <img data-aos="fade-down" className='ml-[-7%] mr-[-12%] w-[80%]' src='/svg/services-innovative-text.svg' alt='' />
                    <img data-aos="fade-left" className='w-[10%] mb-[10%] animate-swing' src='/svg/hand-wave.svg' alt='' />
                </div>
            </div>
            <OurServices serviceScreen={true} whyChooseSection={true} />
            <Branding />
            {/* <ServiceTable /> */}
            <ProjectStats />
            <Technologies />
            <div className='mx-auto lg:mx-[-100px] px-[3%] md:px-[10%] py-[5%] bg-light-theme-light-gradient mt-[100px] flex flex-col gap-5 items-center justify-center'>
                <span className='text-[30px] md:text-[37px] md:leading-[47px] font-[700] text-center'>Not sure what service you need?</span>
                <span className='text-[20px] md:text-[25px] md:leading-[35px] font-[400] text-center'>Let us carefully analyze your business situation and goals to offer you a proper solution</span>
                <ButtonComponent inputStyles={{ padding: '25px', width: '190px', marginLeft: '53px', md: "marginLeft: '0px'", background: 'white', marginTop: '40px', borderRadius: '16px' }} labelClassname="text-theme text-[18px] leading-[24px] font-[500]" onClick={() => { navigate('/getInTouch') }} label={"Contact us"} />
            </div>
            <Testimonials />
            <ContactUs />
        </div>
    )
}

export default ServicesPage