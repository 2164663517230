import React from 'react'
import ContactUs from '../components/molecules/ContactUs'
import Intro from '../components/organisms/Intro'
import Testimonials from '../components/organisms/Testimonials'
import OurServices from '../components/organisms/OurServices'
import Technologies from '../components/organisms/Technologies'
import CareerBenefits from '../components/organisms/CareerBenefits/CareerBenefits'
import CareerIntro from '../components/organisms/CareerIntro/CareerIntro'
import CareerFaq from '../components/organisms/CareerFaq/CareerFaq';
import { motion } from 'framer-motion'
import JoinUsForm from '../components/molecules/JoinUsForm'
import { Helmet } from 'react-helmet'

const CareerPage = () => {
    return (
        <div className='text-black pb-6 relative px-2 md:px-[20px] lg:px-[160px] '>
            <Helmet>
                <link rel="canonical" href="https://thecodingstudio.in/career" />
                <link rel="alternate" href="https://thecodingstudio.in/career" hreflang="x-default" />
            </Helmet>
            <div className='absolute w-[24%] md:w-[20%] top-[0.2%] left-[3%] md:top-[1%] md:left-[13%]'>
                <img data-aos="fade-right" className=' ' src='/svg/career-img.svg' alt='career' />
            </div>
            <div className='flex flex-col items-center px-[50px] md:px-[150px] bg-about-us-square bg-no-repeat bg-cover pb-auto md:pb-[15px] pt-[50px] md:pt-[120px] w-[100%] relative z-[1] justify-center '>
                <div className='flex items-center justify-center '>
                    <img data-aos="zoom-in" className=' w-[100%] pt-[10%]' src='/svg/career-text-img.svg' alt='text' />
                </div>
                <motion.p initial={{ opacity: 0 }}
                    animate={{ opacity: [0.5, 1, 0.5] }}
                    transition={{ duration: 2, repeat: Infinity }} data-aos="zoom-in" className=' text-[15vw] font-bold tracking-[15px] z-0 text-transparent [background-clip:text_!important] [background:_linear-gradient(to_bottom,_#FF5D22_-27%,_#fff_86%)] uppercase'>
                    Career
                </motion.p>
            </div>
            <CareerBenefits />
            <CareerIntro />
            <CareerFaq />
            <div className=' mt-[100px]'>
                <JoinUsForm />
            </div>
            <ContactUs />
        </div>
    )
}

export default CareerPage