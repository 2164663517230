import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AboutUsPage from '../pages/AboutUsPage';
import HomePage from '../pages/Home';
import ServicesPage from '../pages/Services';
import CareerPage from '../pages/CareerPage';
import ServiceSubPage from '../pages/ServiceSubPage';
import GetInTouch from '../pages/GetInTouch';
import TeamMates from '../pages/TeamMates';
import CaseStudies from '../pages/CaseStudies';
import GalleryPage from '../pages/GalleryPage';
import LanguagePage from '../pages/LanguagePage';
import ProjectDetailsPage from '../pages/ProjectDetailsPage';
import ProfileSubPage from '../pages/ProfileSubPage';
import { HelmetProvider } from 'react-helmet-async';

const AppRoutes = () => {

    return (

        <div className='h-[100%]'>
            <HelmetProvider>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/about-us' element={<AboutUsPage />} />
                    <Route path='/services' element={<ServicesPage />} />
                    <Route path='/services/:tech' element={<ServiceSubPage />} />
                    <Route path='/career' element={<CareerPage />} />
                    <Route path='/getInTouch' element={<GetInTouch />} />
                    <Route path={`/profile-page/:name`} element={<ProfileSubPage />} />
                    <Route path='/team-members' element={<TeamMates />} />
                    <Route path='/case-studies' element={<CaseStudies />} />
                    <Route path='/gallery' element={<GalleryPage />} />
                    <Route path='/services/language-page' element={<LanguagePage />} />
                    <Route path='/project-details' element={<ProjectDetailsPage />} />
                </Routes>
            </HelmetProvider>
        </div>
    );
};

export default AppRoutes;
